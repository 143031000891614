"use client";
import React, { useEffect, useState } from "react";

import { ChevronRightIcon } from "lucide-react";
import { useSelector } from "react-redux";
import BasicDetails from "./BasicDetails";
import useSWR from "swr";
import { handleGetUserById } from "../api";
import ChangePassword from "./ChangePassword";
import { Helmet } from "react-helmet-async";
import { CanonicalURL } from "../BASE_URL";

const Profile = () => {
  const [activeItem, setActiveItem] = useState("Basic Details");
  const { user } = useSelector((state) => state.auth);
  const [newdata, setData] = useState({});
  const [updatedProfile, setUpdatedProfile] = useState(false);
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    localStorage.setItem("activeItem", activeItem);
  }, [activeItem]);

  useEffect(() => {
    const storedActiveItem = localStorage.getItem("activeItem");
    if (storedActiveItem) {
      setActiveItem(storedActiveItem);
    }
  }, []);

  const NavItems = [
    {
      name: "Basic Details",
      href: "/prodile/basic-details",
    },
    {
      name: "Change Password",
      href: "/profile/change-password",
    },
    {
      name: "Billing Information",
      href: "/profile/billing-info",
    },
    {
      name: "Settings",
      href: "/profile/billing-info",
    },
    {
      name: "Sign Out",
      href: "/profile/billing-info",
    },
  ];

  const renderProfileSection = () => {
    switch (activeItem) {
      case "Basic Details":
        return (
          <BasicDetails
            newdata={newdata}
            setData={setData}
            setLoading={setLoading}
          />
        );
      case "Change Password":
        return <ChangePassword />;
      case "Billing Information":
        return "hello3";

      default:
        return null;
    }
  };
  return (
    <div className="mt-10">
      <Helmet>
        <title>Indexer Profile</title>
        <meta
          name="description"
          content="Unlock the power of Indexer - your ultimate solution for automatic website indexing.Boost online visibility with our cutting-edge tools for rapid,efficient page indexing."
        />
        <link rel="canonical" href={`${CanonicalURL}/profile`} />
      </Helmet>
      <div className="grid grid-cols-4 gap-7">
        <div>
          <div className="w-full cursor-pointer  rounded-[20px] p-3 border border-gray-200 shadow-sm">
            <div className="w-24 h-24 mx-auto">
              {!Loading ? (
                <img
                  src={newdata?.image}
                  alt="profile image"
                  className="rounded-full"
                />
              ) : (
                <div className="flex justify-center items-center">
                  <p className="font-medium text-[50px] ">
                    {user?.name.substr(0, 1)}
                  </p>
                </div>
              )}
            </div>

            <div className="mt-4 mx-auto">
              <p className="font-medium antialiased tracking-normal text-center">
                {user?.name}
              </p>
            </div>
            <div className="mt-10 flex flex-col gap-8 ">
              {NavItems.map((item, index) => (
                <div className="" key={index}>
                  <div
                    className={`flex items-center justify-between  ${
                      activeItem === item.name
                        ? "bg-[#ebf9f5] rounded-[8px] p-3 text-indigo-400"
                        : "hover:bg-gray-100 p-3 hover:rounded-[8px]"
                    }`}
                    onClick={() => setActiveItem(item.name)}
                  >
                    <p>{item.name}</p>
                    <ChevronRightIcon size={18} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-span-3">
          <div className="w-full cursor-pointer  rounded-[20px] border border-gray shadow-sm">
            <div className="">
              <div className="p-5">{renderProfileSection()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
