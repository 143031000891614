"use client";
import { createContext, useEffect, useState } from "react";
import cookie from "js-cookie";

export const userContext = createContext(null);

const ContextProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const savedData = cookie.get("data");

    return savedData
      ? JSON.parse(savedData)
      : {
          email: "",
        };
  });

  useEffect(() => {
    cookie.set("data", JSON.stringify(data));
  }, [data]);

  return (
    <userContext.Provider value={{ data, setData }}>
      {children}
    </userContext.Provider>
  );
};

export default ContextProvider;
