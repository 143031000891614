import React, { useState } from "react";
import useSWR, { mutate } from "swr";
import { allUploads, getAllserviceAccounts, handleindexUrls } from "../api";
import { useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { RefreshCcw } from "lucide-react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { CanonicalURL } from "../BASE_URL";

const ViewPages = () => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  let counter = 0;

  const fetcher = async () => {
    const response = await allUploads(id);

    return response?.data;
  };

  const fetcher1 = async () => {
    const response = await getAllserviceAccounts(user?._id);

    return response?.data;
  };

  const res1 = useSWR("serviceAccount", fetcher1);

  const { data, isLoading } = useSWR("pages", fetcher);

  const handleInstanstIndexUrls = async () => {
    setLoading(true);

    try {
      const res = await handleindexUrls(res1?.data[0], user?._id, id);

      if (res.status === 200) {
        mutate("pages");
        toast.success(res?.data?.message, {
          position: "top-right",
        });
        navigate("/index-report");
      } else {
        toast.error(res?.data?.error, {
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // const handleAllInstantIndexUrls = async () => {
  //   for (let i = 0; i < data.length; i++) {
  //     const { _id, indexUrls } = data[i];

  //     if (_id && indexUrls && Array.isArray(indexUrls)) {
  //       let foundUrlToIndex = false;

  //       for (const indexUrl of indexUrls) {
  //         if (indexUrl.urlType === "") {
  //           await handleInstanstIndexUrls(_id);
  //           foundUrlToIndex = true;
  //           break;
  //         }
  //       }

  //       if (!foundUrlToIndex) {
  //         // Check if the first ID's URLs count is less than 100
  //         const firstIdUrlsCount = indexUrls.length;

  //         console.log(firstIdUrlsCount);
  //         if (firstIdUrlsCount < 100) {
  //           // Your logic to get the next set of URLs for the next ID
  //           const nextIdIndex = i + 1;

  //           console.log(nextIdIndex);
  //           const nextId = data[nextIdIndex];

  //           console.log(nextId);

  //           if (nextId) {
  //             // Handle the next set of URLs for the next ID
  //             await handleInstanstIndexUrls(nextId._id);
  //           } else {
  //           }
  //         } else {
  //           toast.info("No more URLs to index", {
  //             position: "top-right",
  //           });
  //         }
  //       }
  //     }
  //   }
  // };

  return (
    <div>
      <Helmet>
        <title>Swift Indexer View pages</title>
        <meta
          name="description"
          content="Unlock the power of Indexer - your ultimate solution for automatic website indexing.Boost online visibility with our cutting-edge tools for rapid,efficient page indexing."
        />
        <link rel="canonical" href={`${CanonicalURL}/viewpages/${id}`} />
      </Helmet>
      <div className="flex items-center justify-between">
        <h1 className="font-semibold antialiased tracking-wide leading-normal text-[24px]">
          Your Pages
        </h1>
        <motion.button
          onClick={() => handleInstanstIndexUrls()}
          disabled={loading}
          className={`bg-indigo-400 text-white rounded-[8px] h-10  w-[220px]  flex items-center justify-center gap-2 ${
            loading
              ? "opacity-70 cursor-not-allowed"
              : "hover:ring-2 hover:ring-indigo-300 hover:transition-all hover:duration-300"
          }`}
        >
          <motion.div>
            <RefreshCcw
              className={`w-4 h-4 mt-[2px] ${loading && "animate-spin"}`}
            />
          </motion.div>
          <p>{loading ? "indexing..." : "Index Pages"}</p>
        </motion.button>
      </div>

      {isLoading && !data && (
        <div className="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2">
          <div aria-label="Loading..." role="status">
            <svg
              className="animate-spin w-6 h-6 fill-slate-800"
              viewBox="3 3 18 18"
            >
              <path
                className="opacity-20"
                d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
              ></path>
              <path d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
            </svg>
          </div>
        </div>
      )}

      {data?.length > 0 ? (
        <>
          <p className="text-[14px]  mt-10">
            Total URLs :{" "}
            <span className="font-medium antialiased tracking-normal ">
              {" "}
              {data.reduce((acc, item) => acc + item.indexUrls.length, 0)}
            </span>
          </p>
          <div className=" overflow-x-auto mt-3 zIndex-[100px]">
            <table className="w-full text-sm text-left rtl:text-right border border-gray-200 shadow-sm zIndex-[100px]">
              <thead className="text-xs  uppercase bg-gray-50  border-b">
                <tr>
                  <th scope="col" className="px-6 py-3 border-r">
                    s.no
                  </th>
                  <th scope="col" className="px-6 py-3 border-r">
                    URL
                  </th>
                  <th scope="col" className="px-6 py-3 border-r">
                    created at
                  </th>
                  <th scope="col" className="px-6 py-3 border-r">
                    type
                  </th>
                  <th scope="col" className="px-6 py-3 border-r">
                    indexed
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) =>
                  item.indexUrls.map((url, i) => {
                    counter++;
                    return (
                      <tr className="bg-white border-b" key={index + i}>
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium  whitespace-nowrap border-r"
                        >
                          {counter}
                        </th>
                        <td className="px-6 py-4 border-r">{url?.url}</td>
                        <td className="px-6 py-4 border-r">
                          {url?.createdDate}
                        </td>
                        <td className="px-6 py-4 border-r">
                          {item.UploadedFileType}
                        </td>
                        <td className="px-6 py-4 border-r">
                          {url.urlType === "URL_UPDATED" ||
                          url.urlType === "URL_DELETED"
                            ? "Yes"
                            : "No"}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div
          className={`absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ${
            isLoading && "hidden"
          }`}
        >
          No Pages Found
        </div>
      )}
    </div>
  );
};

export default ViewPages;
