import React, { useEffect, useState } from "react";
import {
  allWebsites,
  createConsoleReport,
  getAllserviceAccounts,
  handleSeoReports,
  handlegetConsoleReport,
} from "../api";
import { useSelector } from "react-redux";
import useSWR, { mutate } from "swr";
import Chart from "react-google-charts";
import WebsiteSelector from "./WebsiteSelector";
import UrlInspectionTable from "./ConsoleReportTable";
import { Link } from "react-router-dom";
import { convertToIST, convertToIST1 } from "../utils/functions";
import { Helmet } from "react-helmet-async";
import { CanonicalURL } from "../BASE_URL";

const ConsoleReport = ({}) => {
  const { user } = useSelector((state) => state.auth);
  const [chartData, setChartData] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [websites, setWebsites] = useState([]);
  const [selected, setSelected] = useState();
  const [Loading, setLoading] = useState(false);
  const [Loading1, setLoading1] = useState(false);
  const [error1, setError1] = useState("");
  const [data, setData] = useState([]);
  const [maxClicks, setMaxClicks] = useState(0);
  const [maxImpressions, setMaxImpressions] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const fetcher2 = async () => {
    if (user) {
      const response = await allWebsites(user?._id);

      setWebsites(response?.data);
    }
  };

  const fetcher1 = async () => {
    if (user) {
      const result = await getAllserviceAccounts(user?._id);

      return result;
    }
  };

  const res = useSWR("service", fetcher1);

  const fetcher = async () => {
    setLoading1(true);
    try {
      const result = await handlegetConsoleReport(user?._id);

      const today = new Date().toLocaleDateString("en-CA");

      if (
        result?.data?.length > 0 &&
        result?.data?.[0]?.lastUpdatedConsoleReportAt === today
      ) {
        setData(result?.data);
      } else {
        if (selected && selectedId) {
          try {
            const result = await createConsoleReport(
              res?.data?.data[0],
              selected,
              selectedId,
              user?._id
            );
            if (result?.data?.error?.code === 403) {
              setError1(result?.data?.error?.message);
            } else {
              setRefresh((prev) => !prev);
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  useEffect(() => {
    const formattedData = [["Date", "Clicks", "Impressions"]];
    if (data && data.length > 0) {
      data?.[0]?.report?.forEach((item) => {
        formattedData.push([
          new Date(item.date).toLocaleDateString(),
          parseInt(item.clicks),
          parseInt(item.impressions),
        ]);
      });
    }
    setChartData(formattedData);
  }, [data]);

  useEffect(() => {
    data.forEach((item) => {
      // Access the 'report' array
      const reportEntries = item.report || [];

      // Initialize arrays to store parsed clicks and impressions for each report entry
      const parsedClicksArray = reportEntries.map((reportEntry) => {
        // Access 'clicks' property from each entry in the 'report' array
        const rawClicks = reportEntry ? reportEntry.clicks : undefined;

        // Ensure that rawClicks is defined and not an empty string before parsing
        return rawClicks ? parseInt(rawClicks) : 0;
      });

      const parsedImpressionsArray = reportEntries.map((reportEntry) => {
        // Access 'impressions' property from each entry in the 'report' array
        const rawImpressions = reportEntry
          ? reportEntry.impressions
          : undefined;

        // Ensure that rawImpressions is defined and not an empty string before parsing
        return rawImpressions ? parseInt(rawImpressions) : 0;
      });

      // Find the maximum value from the parsedClicksArray
      const maxClicksForEntry = Math.max(...parsedClicksArray);

      // Find the maximum value from the parsedImpressionsArray
      const maxImpressionsForEntry = Math.max(...parsedImpressionsArray);

      // Update the state if the new maxClicksForEntry is greater than the current maxClicks
      setMaxClicks((prevMaxClicks) =>
        Math.max(prevMaxClicks, maxClicksForEntry)
      );

      // Update the state if the new maxImpressionsForEntry is greater than the current maxImpressions
      setMaxImpressions((prevMaxImpressions) =>
        Math.max(prevMaxImpressions, maxImpressionsForEntry)
      );
    });
  }, [data]);

  useEffect(() => {
    Promise.all([fetcher(), fetcher2()]);
  }, [selectedId, refresh]);

  const evenMaxClicks = maxClicks % 2 === 0 ? maxClicks : maxClicks + 1;
  const evenMaxImpressions =
    maxImpressions % 2 === 0 ? maxImpressions : maxImpressions + 1;

  const roundedEvenMaxClicks =
    evenMaxClicks % 2 === 0 ? evenMaxClicks + 2 : evenMaxClicks + 1;
  const roundedEvenMaxImpressions =
    evenMaxImpressions % 2 === 0
      ? evenMaxImpressions + 2
      : evenMaxImpressions + 1;

  // Generate an array of even tick values
  const generateEvenTicks = (maxValue, numTicks) => {
    const evenTicks = [];
    for (let i = 0; i < numTicks; i++) {
      const tickValue = (maxValue / (numTicks - 1)) * i;
      evenTicks.push(Math.round(tickValue));
    }
    return evenTicks;
  };

  const seoReports = async () => {
    try {
      if (selected && selectedId) {
        const result = await handleSeoReports(
          res?.data?.data[0],
          selected,
          selectedId,
          user?._id
        );

        if (result) {
          console.log(result);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Swift Indexer ConsoleReport</title>
        <meta
          name="description"
          content="Unlock the power of Indexer - your ultimate solution for automatic website indexing.Boost online visibility with our cutting-edge tools for rapid,efficient page indexing."
        />
        <link rel="canonical" href={`${CanonicalURL}/console-report`} />
      </Helmet>
      <div className="flex justify-between items-center">
        <h1 className="font-semibold antialiased tracking-wide leading-normal text-[24px] w-full">
          Console Report
        </h1>

        <WebsiteSelector
          websites={websites}
          setSelected={setSelected}
          selected={selected}
          setSelectedId={setSelectedId}
        />
      </div>

      <div className="w-full cursor-pointer rounded-[20px] bg-white border border-gray-200 p-5 mt-10">
        <div className="z-1 h-[450px]">
          <div>
            <div className="flex items-center justify-between flex-wrap">
              <p className="font-medium antialiased tracking-normal">
                Website visits from last 3 months
              </p>

              <p className="text-slate-400 antialiased tracking-normal">
                Last updated :{" "}
                <span className="text-slate-700">
                  {convertToIST1(data?.[0]?.lastUpdatedConsoleReportAt)}
                </span>
              </p>
            </div>
          </div>

          {!error1 && !Loading1 && data.length > 0 && (
            <Chart
              width={"100%"}
              height={"420px"}
              chartType="LineChart"
              data={chartData}
              options={{
                series: {
                  0: {
                    targetAxisIndex: 0,

                    color: "#dc3912",
                    curveType: "monotone",
                  },
                  1: {
                    targetAxisIndex: 1,

                    color: "#3366cc",

                    curveType: "monotone",
                  },
                },
                hAxis: {
                  format: "dd/MM/yyyy",
                },
                vAxes: {
                  0: {
                    title: "Clicks",
                    viewWindow: {
                      max: roundedEvenMaxClicks, // Set your custom maximum value for Clicks here
                    },
                    ticks: generateEvenTicks(roundedEvenMaxClicks, 4),
                  },
                  1: {
                    title: "Impressions",
                    viewWindow: {
                      max: roundedEvenMaxImpressions, // Set your custom maximum value for Impressions here
                    },
                    ticks: generateEvenTicks(roundedEvenMaxImpressions, 4),

                    gridlines: {
                      color: "transparent", // Set the gridlines color to transparent
                    },
                  },
                },
                legend: {
                  position: "right",
                },
              }}
            />
          )}

          <div
            className={`flex justify-center items-center h-full flex-col ${
              Loading && "hidden"
            }`}
          >
            {Loading1 && (
              <div className="flex justify-center items-center h-full flex-col">
                <div aria-label="Loading..." role="status">
                  <svg
                    className="animate-spin w-6 h-6 fill-slate-800"
                    viewBox="3 3 18 18"
                  >
                    <path
                      className="opacity-20"
                      d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                    ></path>
                    <path d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
                  </svg>
                </div>

                <p>Please wait we are fetching your report</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mt-6">
        <UrlInspectionTable
          selectedId={selectedId}
          serviceAccount={res?.data?.data[0]}
          selected={selected}
        />
      </div>
    </div>
  );
};

export default ConsoleReport;
