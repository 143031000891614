import React, { useState } from "react";
import { HiOutlineTrash } from "react-icons/hi2";

const UploadProfile = ({ setImageFile }) => {
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState("");

  const handleFile = (e) => {
    setMessage("");
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setImageFile(selectedFile);
      const fileType = selectedFile.type;
      const validImageTypes = ["image/jpeg", "image/png"];

      if (validImageTypes.includes(fileType)) {
        const reader = new FileReader();

        reader.onload = (event) => {
          const imagePreview = event.target.result;
          // Replace the old file with the new one
          setFiles([{ file: selectedFile, imagePreview }]);
        };

        reader.readAsDataURL(selectedFile);
      } else {
        setMessage("Only images accepted");
      }
    }
  };

  const removeImage = (fileName) => {
    setFiles((prevFiles) =>
      prevFiles.filter((file) => file.file.name !== fileName)
    );
  };

  return (
    <>
      <div className=" w-full rounded-md ">
        <span className="flex justify-center items-center bg-white text-[12px] mb-1 text-red-500">
          {message}
        </span>
        <div className="h-44 w-full overflow-hidden relative border-dashed border-2 bg-[#f7f7f7] border-[#c9d4e1] items-center rounded-md cursor-pointer ">
          <input
            type="file"
            onChange={handleFile}
            className="h-full w-full opacity-0 z-10 absolute"
            multiple="multiple"
            name="files[]"
          />
          <div className="h-full w-full  absolute z-1 flex justify-center items-center top-0">
            <div className="flex flex-col items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6 text-gray-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                />
              </svg>
              <h1 className="desc text-[13px]">
                Drag and drop profile image file or click here
              </h1>
              <p className="desc text-center text-[12px] mt-1 text-gray-400">
                Supports : JPG,PNG
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-2 mt-2">
          {files.map((file, key) => (
            <div
              key={key}
              className=" w-full h-auto flex items-center rounded p-3 bg-white border border-gray-100 justify-between"
            >
              <div className="flex flex-row items-center gap-3">
                <div className="rounded-[8px] ">
                  <img
                    width={100}
                    height={100}
                    src={file.imagePreview}
                    alt={file.file.name}
                    className="rounded-[8px]"
                  />
                </div>
                <span className="">{file.file.name}</span>
              </div>
              <div
                onClick={() => {
                  removeImage(file.file.name);
                }}
                className="h-6 w-6  flex items-end cursor-pointer justify-end rounded-sm"
              >
                <HiOutlineTrash
                  fontSize={24}
                  className="hover:text-indigo-400"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default UploadProfile;
