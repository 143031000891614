import React, { useEffect, useRef, useState } from "react";
import { allUploads, allWebsites, getAllserviceAccounts } from "../api";
import useSWR from "swr";
import { useSelector } from "react-redux";
import WebsiteSelector from "./WebsiteSelector";
import ConsoleReportDashboard from "./ConsoleReportDashboard";
import { convertToIST, extractDomain } from "../utils/functions";
import { Link } from "lucide-react";
import DashboardUrlInspectionTable from "./DashboardUrlInspectionTable";
import { Helmet } from "react-helmet-async";
import { CanonicalURL } from "../BASE_URL";
import { useNavigate } from "react-router-dom";

const Dashboard = ({ serviceAccounts }) => {
  const [selected, setSelected] = useState();
  const [websites, setWebsites] = useState([]);
  const [selectedId, setSelectedId] = useState();

  const [data, setData] = useState([]);
  const { user, token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const initialLoadRef = useRef(true);
  const fetcher = async () => {
    if (user) {
      const response = await allWebsites(user?._id);

      setWebsites(response?.data);
    }
  };

  useEffect(() => {
    fetcher();
  }, [user, token]);

  const fetcher1 = async () => {
    if (selectedId) {
      const response = await allUploads(selectedId);

      setData(response?.data);
    }
  };

  useEffect(() => {
    fetcher1();
  }, [user, selectedId]);

  const TotalUrlsforAddingIndexing =
    data &&
    data
      ?.filter((item) => item?.UploadedFileType === "Add")
      .map((item) => item);

  const TotalUrlsforupdateIndexing =
    data &&
    data
      ?.filter((item) => item?.UploadedFileType === "update")
      .map((item) => item);

  const TotalUrlsforremoveIndexing =
    data &&
    data
      ?.filter((item) => item?.UploadedFileType === "remove")
      .map((item) => item);

  const TotalUrlIndexed =
    data &&
    data
      ?.map((item) => item.indexUrls.filter((url) => url.urlType !== "").length)
      .reduce((acc, count) => acc + count, 0);

  const totalAddIndexingUrlsLength = TotalUrlsforAddingIndexing?.map(
    (item) => item.indexUrls.length
  ).reduce((acc, length) => acc + length, 0);

  const totalUpdateIndexingUrlsLength = TotalUrlsforupdateIndexing?.map(
    (item) => item.indexUrls.length
  ).reduce((acc, length) => acc + length, 0);

  const totalRemoveIndexingUrlsLength = TotalUrlsforremoveIndexing?.map(
    (item) => item.indexUrls.length
  ).reduce((acc, length) => acc + length, 0);

  const TotalURLSubmitted =
    totalAddIndexingUrlsLength +
    totalUpdateIndexingUrlsLength +
    totalRemoveIndexingUrlsLength;
  const UrlsData = [
    {
      name: "Total URLs submitted",
      value: TotalURLSubmitted || 0,
      subtitle: `URLs still in pending state
      `,
    },
    {
      name: "Total URLs indexed",
      value: TotalUrlIndexed || 0,
      subtitle: `URLs still in pending state
      `,
    },
    {
      name: "Total URLs submitted to add Indexing",
      value: totalAddIndexingUrlsLength || 0,
      subtitle: `Recent URLs added at ${convertToIST(
        TotalUrlsforAddingIndexing?.[0]?.createdAt
      )}`,
    },
    {
      name: "Total URLs submitted to update Indexing",
      value: totalUpdateIndexingUrlsLength || 0,
      subtitle: ` Recent URLs added at ${
        TotalUrlsforupdateIndexing?.[0]?.createdAt
          ? convertToIST(TotalUrlsforupdateIndexing?.[0]?.createdAt)
          : "NA"
      }`,
    },
    {
      name: "Total URLs submitted to remove Indexing",
      value: totalRemoveIndexingUrlsLength || 0,
      subtitle: `Recent URLs added at ${
        TotalUrlsforremoveIndexing?.[0]?.createdAt
          ? convertToIST(TotalUrlsforremoveIndexing?.[0]?.createdAt)
          : "NA"
      }`,
    },

    {
      name: "Total URLs pending to index",
      value: TotalURLSubmitted - TotalUrlIndexed || 0,
      subtitle: `URLs still in pending state
      `,
    },
  ];

  return (
    <div className="z-[100px]">
      <Helmet>
        <title>Swift Indexer Dashboard</title>
        <meta
          name="description"
          content="Unlock the power of Indexer - your ultimate solution for automatic website indexing.Boost online visibility with our cutting-edge tools for rapid,efficient page indexing."
        />
        <link rel="canonical" href={`${CanonicalURL}/dashboard`} />
      </Helmet>
      <h1 className=" antialiased tracking-wide leading-normal text-[24px] font-semibold">
        Dashboard
      </h1>

      <div className="mt-10 flex items-center gap-3 flex-wrap">
        <p className="">
          <span className=""> Welcome</span>,{" "}
          <span className="text-blue-400 antialiased font-medium">
            {user?.name}
          </span>
        </p>

        <p className="antialiased tracking-normal text-slate-400">{`[ ${
          selected ? selected : "fetching..."
        } ]`}</p>
        <div className="z-0">
          <WebsiteSelector
            websites={websites}
            setSelected={setSelected}
            selected={selected}
            setSelectedId={setSelectedId}
          />
        </div>
      </div>

      <div className="mt-6">
        <ConsoleReportDashboard selected={selected} selectedId={selectedId} />
      </div>

      <div className="mt-6">
        <div className="grid grid-cols-1 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 gap-5">
          {UrlsData?.map((item, index) => (
            <div
              className="bg-white border border-gray-200 rounded-[20px] p-6"
              key={index}
            >
              <div className="flex justify-between items-center">
                <p className="font-medium antialiased tracking-normal">
                  {item.name}
                </p>

                <Link size={17} className="text-slate-400" />
              </div>

              <div className="mt-2 ">
                <p className="text-2xl font-bold">{item.value}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-6">
        <DashboardUrlInspectionTable data={data} />
      </div>
    </div>
  );
};

export default Dashboard;
