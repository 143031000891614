import { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import {
  Bars3Icon,
  UserCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import Container from "./Container";
import "../App.css";

import { motion } from "framer-motion";
import {
  ArrowRightIcon,
  CalendarDays,
  ChevronDownIcon,
  ChevronUpIcon,
  LogOutIcon,
  SquareEqualIcon,
  UserCircle2,
  UserIcon,
} from "lucide-react";

import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import ProfileDropdown from "./ProfileDropdown";
import { logout } from "../redux/actions/authActions";

const navigation = [
  { name: "Pricing", href: "/pricing" },
  { name: "Features", href: "#" },
  { name: "About Us", href: "#" },
];

const navigation1 = [
  {
    name: "Dashboard",
    href: "/dashboard",
  },
  {
    name: "Websites",
    href: "/websites",
  },

  {
    name: "Index Report",
    href: "/index-report",
  },
  {
    name: "Console Report",
    href: "/console-report",
  },
  // {
  //   name: "Seo Report",
  //   href: "/seo-report",
  // },
];

export default function Navbar({ session }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { token, user } = useSelector((state) => state.auth);

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
    localStorage.setItem("activeItem", itemName);
  };

  useEffect(() => {
    const storedItem = localStorage.getItem("activeItem");
    if (storedItem) {
      setActiveItem(storedItem);
    }
  }, []);

  const handleLogout = () => {
    const res = dispatch(logout());

    if (res) {
      navigate("/");
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 30 && window.location.pathname === "/") {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Add event listener for scroll
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleHover = (itemName) => {
    setHoveredItem(itemName);
  };

  return (
    <div
      className={`bg-white h-24 sticky top-0 z-40 border-b border-gray-100 drop-shadow-sm transition-all duration-300`}
    >
      <Container>
        <header className="p-5">
          <nav
            className="flex items-center justify-between  "
            aria-label="Global"
          >
            <div className="flex lg:flex-1">
              <Link to="/dashboard" className="-m-1.5 p-1.5 ">
                <span className="sr-only">Your Company</span>

                <div className="w-[200px] object-contain ">
                  <img src={logo} alt="logo" width="100%" height="100%" />
                </div>
              </Link>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>

            {!token ? (
              <div className="hidden lg:flex lg:gap-x-12">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={`  hover:transition-all hover:duration-300 leading-6  ${
                      activeItem === item.name ? "text-blue-500 " : ""
                    }`}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            ) : (
              <div className="lg:flex lg:gap-x-12 hidden">
                {navigation1.map((parentItem, index) => (
                  <div
                    key={index}
                    className="relative inline-block"
                    onMouseEnter={() => handleHover(parentItem.name)}
                    onMouseLeave={() => handleHover(null)}
                  >
                    <NavLink
                      to={parentItem.href}
                      className={({ isActive }) => {
                        return isActive
                          ? "text-blue-600 antialiased tracking-normal transition-all duration-300"
                          : "hover:text-indigo-400 transition-all duration-300";
                      }}
                    >
                      {parentItem.children ? (
                        <div className="flex items-center ">
                          <p>Indexing</p>
                          {hoveredItem === parentItem.name ? (
                            <motion.div
                              animate={{ rotate: -180 }}
                              transition={{ duration: 0.3 }}
                              className="mt-1"
                            >
                              <ChevronDownIcon size={18} />
                            </motion.div>
                          ) : (
                            <motion.div
                              animate={{ rotate: 180 }}
                              transition={{ duration: 0.3 }}
                              className="mt-1"
                            >
                              <ChevronUpIcon size={18} />
                            </motion.div>
                          )}
                        </div>
                      ) : (
                        <p>{parentItem.name}</p>
                      )}
                    </NavLink>

                    {parentItem.children && hoveredItem === parentItem.name && (
                      <div className="bg-white drop-shadow-md top-[100%]  flex flex-col absolute w-[200px] p-3 gap-2 rounded-[8px]">
                        {parentItem.children.map((child, childIndex) => (
                          <Link
                            key={childIndex}
                            href={child.href}
                            className="hover:bg-gray-100 rounded-[8px] p-2"
                          >
                            {child.name}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}

            {token ? (
              <div className="lg:flex lg:flex-1 lg:justify-end lg:items-center lg:gap-5 hidden">
                <ProfileDropdown />
              </div>
            ) : (
              <div className="hidden lg:flex lg:flex-1 lg:justify-end lg:items-center lg:gap-5">
                <Link to="/">
                  <p className=" hover:text-slate-800 hover:transition-all hover:duration-300 ">
                    Sign in
                  </p>
                </Link>

                <Link to="/sign-up">
                  <motion.button
                    onHoverStart={() => setIsHovered(true)}
                    onHoverEnd={() => setIsHovered(false)}
                    className="bg-indigo-400 h-10 w-[120px] rounded-[8px] text-white hover:ring-2 hover:ring-indigo-200 hover:transition-all hover:duration-300 flex justify-center items-center gap-1 "
                  >
                    <p>Sign Up</p>
                    <motion.div
                      initial={{ x: 0 }}
                      animate={{ x: isHovered ? 5 : 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      <ArrowRightIcon className="w-4 h-4 mt-[2px]" />
                    </motion.div>
                  </motion.button>
                </Link>
              </div>
            )}
          </nav>
          <Dialog
            as="div"
            className="lg:hidden"
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
          >
            <div className="fixed inset-0 z-50" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img className="h-8 w-auto" src={logo} alt="Logo" />
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {token && (
                <div className="mt-4 flow-root">
                  <p className="font-semibold antialiased tracking-normal">
                    welcome ,<span className="text-blue-400"> {user.name}</span>
                  </p>
                  <p className="text-slate-600 antialiased tracking-normal">
                    {user.email}
                  </p>
                  <p className="border-b border-gray-500/10 mt-2"></p>
                </div>
              )}
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  {!token ? (
                    <div className="space-y-2 py-6">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className="-mx-3 block rounded-lg px-3 py-2 text-base  leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  ) : (
                    <div>
                      {navigation1.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className="-mx-3 block rounded-lg px-3 py-2 text-base  leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  )}
                  <div className="py-0">
                    <Link
                      to="/profile"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base  leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Profile
                    </Link>
                    <button
                      onClick={handleLogout}
                      className="border border-gray-100 rounded-[8px] w-full h-10 bg-indigo-400 text-white"
                    >
                      Sign Out
                    </button>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </header>
      </Container>
    </div>
  );
}
