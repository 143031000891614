import React from "react";

const Container = ({ children }) => {
  return (
    <div className="2xl:px-28 xl:px-28  lg:px-20 md:px-8  px-3 ">
      {children}
    </div>
  );
};

export default Container;
