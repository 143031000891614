import React, { useEffect, useState } from "react";
import { allUploads, handleURLInspection } from "../api";
import { convertToIST } from "../utils/functions";

const UrlInspectionTable = ({ selectedId, serviceAccount, selected }) => {
  const [data, setData] = useState([]);

  let counter = 0;
  const fetcher1 = async () => {
    if (selectedId) {
      const response = await allUploads(selectedId);

      setData(response?.data);
    }
  };

  useEffect(() => {
    fetcher1();
  }, [selectedId]);
  return (
    <div>
      {data?.some((item) =>
        item.indexUrls.some((url) => url.urlType !== "")
      ) ? (
        <>
          <div className=" overflow-x-auto mt-3 z-1">
            <table className="w-full text-sm text-left rtl:text-right border border-gray-200 shadow-sm zIndex-[100px]">
              <thead className="text-xs  uppercase bg-gray-50  border-b">
                <tr>
                  <th scope="col" className="px-6 py-3 border-r">
                    S.no
                  </th>
                  <th scope="col" className="px-6 py-3 border-r">
                    URL
                  </th>
                  <th scope="col" className="px-6 py-3 border-r">
                    created at
                  </th>
                  <th scope="col" className="px-6 py-3 border-r">
                    type
                  </th>

                  <th scope="col" className="px-6 py-3 border-r">
                    status
                  </th>
                  <th scope="col" className="px-6 py-3 border-r">
                    last indexed
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) =>
                  item.indexUrls.map((url, i) => {
                    if (url?.urlType !== "") {
                      counter++;
                      return (
                        <tr className="bg-white border-b" key={counter}>
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium  whitespace-nowrap border-r"
                          >
                            {counter}
                          </th>
                          <td className="px-6 py-4 border-r">{url?.url}</td>
                          <td className="px-6 py-4 border-r">
                            {url?.createdDate}
                          </td>
                          <td className="px-6 py-4 border-r">
                            {item.UploadedFileType}
                          </td>

                          <td className="px-6 py-4 border-r">
                            {url?.indexCoverageState || "NA"}
                          </td>
                          <td className="px-6 py-4 border-r">
                            {url?.urlindexedTime === ""
                              ? "0"
                              : url?.urlindexedTime}
                          </td>
                        </tr>
                      );
                    }
                  })
                )}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div
          className={`absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2`}
        ></div>
      )}
    </div>
  );
};

export default UrlInspectionTable;
