import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { allWebsites, getAllserviceAccounts, userLogin } from "../api";
import logo from "../images/logo.png";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { login } from "../redux/actions/authActions";
import { Helmet } from "react-helmet-async";
import { CanonicalURL } from "../BASE_URL";
import "./SignIn.css";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long"),
});

const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetcher1 = async (id) => {
    const response = await getAllserviceAccounts(id);

    return response?.data;
  };

  const fetcher2 = async (id) => {
    const response = await allWebsites(id);

    return response?.data;
  };

  return (
    <div className="flex items-center justify-center min-h-screen flex-col gap-5">
      <Helmet>
        <title>Indexer Login</title>
        <meta
          name="description"
          content="Log in to your Indexer account securely. Gain access to powerful tools and features for efficient management. Join now for a seamless experience."
        />
        <link rel="canonical" href={`${CanonicalURL}/`} />
      </Helmet>

      <div className="w-[280px] flex items-center justify-center mr-6">
        <img src={logo} alt="logo" width="100%" height="100%" />
      </div>
      <div className=" 2xl:w-[450px] xl:w-[450px] lg:w-[450px] md:w-[450px] w-full border border-gray-200 p-6 rounded-[20px] mx-auto">
        <div className="">
          <div className="mt-6">
            <h1 className="text-2xl font-semibold antialiased">Sign In</h1>
            <p className="text-slate-400 antialiased ">
              to continue to Swift index
            </p>
          </div>

          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              try {
                setLoading(true);
                const res = await userLogin(values.email, values.password);

                if (res.status === 200) {
                  const seriveaccounts = await fetcher1(res.data?.user?._id);

                  if (seriveaccounts.length > 0) {
                    const websites = await fetcher2(res.data?.user?._id);

                    if (websites.length > 0) {
                      navigate("/dashboard");
                      dispatch(login(res.data.user?.token, res.data?.user));
                      toast.success("User logged in successfully", {
                        position: "top-right",
                      });
                    } else {
                      navigate("/websites");
                      dispatch(login(res.data.user?.token, res.data?.user));
                      toast.success("User logged in successfully", {
                        position: "top-right",
                      });
                    }
                  } else {
                    navigate("/service/new");
                    dispatch(login(res.data.user?.token, res.data?.user));
                    toast.success("User logged in successfully", {
                      position: "top-right",
                    });
                  }
                } else if (res.status === 201) {
                  navigate("/verify", { state: { data: res.data?.user } });
                } else {
                  toast.error(res?.data?.error, {
                    position: "top-right",
                  });
                }
              } catch (error) {
                console.error(error);
              } finally {
                setLoading(false);
              }
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur }) => (
              <Form>
                <Field
                  type="email"
                  name="email"
                  className={`outline-none border ${
                    errors.email && touched.email
                      ? "border-red-500 focus:ring-2 focus:ring-red-200 focus:transition-all focus:duration-300"
                      : "border-gray-200 focus:ring-2 focus:ring-indigo-200 focus:transition-all focus:duration-300"
                  } w-full h-12 rounded-[8px] focus:ring-2 focus:ring-indigo-200 focus:transition-all focus:duration-300 indent-3 mt-5`}
                  placeholder="Enter your email"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500"
                />

                <Field
                  type="password"
                  name="password"
                  className={`outline-none border ${
                    errors.password && touched.password
                      ? "border-red-500 focus:ring-2 focus:ring-red-200 focus:transition-all focus:duration-300"
                      : "border-gray-200 focus:ring-2 focus:ring-indigo-200 focus:transition-all focus:duration-300"
                  } w-full h-12 rounded-[8px]  indent-3 mt-5`}
                  placeholder="Enter your password"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-500"
                />

                <button
                  type="submit"
                  disabled={loading}
                  className="mt-6 bg-indigo-400 rounded-[8px] h-12 text-white flex justify-center items-center gap-1 w-full"
                >
                  {loading ? (
                    <div aria-label="Loading..." role="status">
                      <svg
                        class="animate-spin w-6 h-6 fill-white"
                        viewBox="3 3 18 18"
                      >
                        <path
                          class="opacity-20"
                          d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                        ></path>
                        <path d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
                      </svg>
                    </div>
                  ) : (
                    <p>Sign In</p>
                  )}
                </button>
              </Form>
            )}
          </Formik>

          <div className="mt-6">
            <p className="text-slate-400 antialiased text-center">
              Don't have an account ?
            </p>
            <div>
              <Link to="/sign-up">
                <p className="text-blue-400  hover:text-orange-400 hover:transition-all hover:duration-300 text-center mt-2">
                  Sign up
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <p>
          © 2023{" "}
          <a
            href="https://www.csquaretech.com/"
            target="_blank"
            className="hover:text-blue-400 antialiased tracking-normal transition-all duration-300 hover:underline text-indigo-400 hover:duration-300 hover:transition-all"
          >
            CSTech
          </a>
          . All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Login;
