"use client";

import { InfoIcon, Trash2Icon } from "lucide-react";
import React, { useState } from "react";

const FileUpload = ({ JSONData, setJSONData }) => {
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState("");

  const handleFile = (e) => {
    setMessage("");
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const fileType = selectedFile.type;
      const validImageTypes = ["application/json"];

      if (validImageTypes.includes(fileType)) {
        const reader = new FileReader();

        reader.onload = (event) => {
          const fileContent = event.target.result;
          const jsonData = JSON.parse(fileContent);
          setJSONData(jsonData);

          if (jsonData?.type !== "service_account") {
            setMessage(`${selectedFile?.name} is not a google service account`);
            setFiles([]);
          } else {
            setFiles([{ file: selectedFile, jsonData }]);
          }
        };

        reader.readAsText(selectedFile);
      } else {
        setMessage("Only JSON file accepted");
      }
    }
  };

  const removeFile = (fileName) => {
    setFiles((prevFiles) =>
      prevFiles.filter((file) => file.file.name !== fileName)
    );
  };

  return (
    <>
      <div className="w-full rounded-md 2xl:w-full cursor-pointer">
        <span className="flex justify-center items-center bg-white text-[12px] mb-1 text-red-500">
          {message}
        </span>
        <div className="h-60 w-full overflow-hidden relative border-dashed border-2 bg-[#f7f7f7] border-[#c9d4e1] items-center rounded-md cursor-pointer">
          <input
            type="file"
            onChange={handleFile}
            className="h-full w-full opacity-0 z-10 absolute"
            name="files[]"
            accept="application/json"
          />
          <div className="h-full w-full absolute z-1 flex justify-center items-center top-0">
            <div className="flex flex-col items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6 text-gray-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                />
              </svg>
              <h1 className="desc text-[13px]">
                Drag and drop JSON file here or click here
              </h1>
              <p className="desc text-center text-[12px] mt-1 text-gray-400">
                Supports: JSON
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-2 mt-2">
          {files.map((file, key) => (
            <div
              key={key}
              className="w-full h-auto flex items-center rounded p-3 bg-white border border-gray-100 justify-between"
            >
              <div className="flex flex-row items-center gap-2">
                <div className="flex items-center gap-4">
                  <p>{file.file.name}</p>
                </div>
              </div>
              <div
                onClick={() => {
                  removeFile(file.file.name);
                }}
                className="h-6 w-6 flex items-end cursor-pointer justify-end rounded-sm"
              >
                <Trash2Icon fontSize={24} className="hover:text-indigo-400" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default FileUpload;
