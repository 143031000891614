import React from "react";

const IndexReportTable = ({ data, isLoading, searchQuery, selectedType }) => {
  let counter = 0;

  const handleSearch = () => {
    let filteredData = data;

    if (selectedType === "Url") {
      filteredData = filteredData.filter((item) =>
        item.indexUrls.some((url) =>
          url.url.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    } else if (selectedType === "Type") {
      filteredData = filteredData.filter((item) =>
        item.UploadedFileType.toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else if (selectedType === "Created At") {
      filteredData = filteredData.filter((item) =>
        item.indexUrls.some((url) =>
          url.createdDate.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    } else if (selectedType === "Status") {
      filteredData = filteredData.filter((item) =>
        item.indexUrls.some((url) =>
          url.indexCoverageState
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
      );
    }

    return filteredData;
  };

  return (
    <div>
      {isLoading && !data && (
        <div className="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2">
          <div aria-label="Loading..." role="status">
            <svg
              className="animate-spin w-6 h-6 fill-slate-800"
              viewBox="3 3 18 18"
            >
              <path
                className="opacity-20"
                d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
              ></path>
              <path d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
            </svg>
          </div>
        </div>
      )}

      {data?.some((item) =>
        item.indexUrls.some((url) => url.urlType !== "")
      ) ? (
        <>
          {handleSearch()?.length > 0 ? (
            <div className=" overflow-x-auto mt-3 z-[100px]">
              <table className="w-full text-sm text-left rtl:text-right border border-gray-200 shadow-sm zIndex-[100px]">
                <thead className="text-xs  uppercase bg-gray-50  border-b">
                  <tr>
                    <th scope="col" className="px-6 py-3 border-r">
                      S.no
                    </th>
                    <th scope="col" className="px-6 py-3 border-r">
                      URL
                    </th>
                    <th scope="col" className="px-6 py-3 border-r">
                      created at
                    </th>
                    <th scope="col" className="px-6 py-3 border-r">
                      type
                    </th>
                    <th scope="col" className="px-6 py-3 border-r">
                      URL type (after indexing)
                    </th>
                    <th scope="col" className="px-6 py-3 border-r">
                      Notify Time (for indexing)
                    </th>

                    <th scope="col" className="px-6 py-3 border-r">
                      status
                    </th>
                    <th scope="col" className="px-6 py-3 border-r">
                      last indexed
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {handleSearch()?.map((item, index) => {
                    return item.indexUrls.map((url, i) => {
                      if (url.urlType !== "") {
                        if (
                          url.url.includes(searchQuery) ||
                          item.UploadedFileType.toLowerCase().includes(
                            searchQuery.toLowerCase()
                          ) ||
                          url.createdDate
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase()) ||
                          url.indexCoverageState
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase())
                        ) {
                          counter++;
                          return (
                            <tr className="bg-white border-b" key={counter}>
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium  whitespace-nowrap border-r"
                              >
                                {counter}
                              </th>
                              <td className="px-6 py-4 border-r">{url?.url}</td>
                              <td className="px-6 py-4 border-r">
                                {url?.createdDate}
                              </td>
                              <td className="px-6 py-4 border-r">
                                {item.UploadedFileType}
                              </td>
                              <td className="px-6 py-4 border-r">
                                {url.urlType}
                              </td>
                              <td className="px-6 py-4 border-r w-12">
                                {url.notifyTime}
                              </td>

                              <td className="px-6 py-4 border-r">
                                {url?.indexCoverageState || "NA"}
                              </td>
                              <td className="px-6 py-4 border-r">
                                {url?.urlindexedTime === "" ||
                                url.indexCoverageState === "Not found (404)" ||
                                url.indexCoverageState ===
                                  "Excluded by ‘noindex’ tag"
                                  ? "0"
                                  : url?.urlindexedTime}
                              </td>
                            </tr>
                          );
                        }
                      } else {
                        return null;
                      }
                    });
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className=" overflow-x-auto mt-3 z-[100px]">
              <table className="w-full text-sm text-left rtl:text-right border border-gray-200 shadow-sm zIndex-[100px]">
                <thead className="text-xs  uppercase bg-gray-50  border-b">
                  <tr>
                    <th scope="col" className="px-6 py-3 border-r">
                      S.no
                    </th>
                    <th scope="col" className="px-6 py-3 border-r">
                      URL
                    </th>
                    <th scope="col" className="px-6 py-3 border-r">
                      created at
                    </th>
                    <th scope="col" className="px-6 py-3 border-r">
                      type
                    </th>
                    <th scope="col" className="px-6 py-3 border-r">
                      URL type (after indexing)
                    </th>
                    <th scope="col" className="px-6 py-3 border-r">
                      Notify Time (for indexing)
                    </th>

                    <th scope="col" className="px-6 py-3 border-r">
                      status
                    </th>
                    <th scope="col" className="px-6 py-3 border-r">
                      last indexed
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center py-4" colSpan="8">
                      No data found
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </>
      ) : (
        <div
          className={`absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ${
            isLoading && "hidden"
          }`}
        >
          No indexed urls found
        </div>
      )}
    </div>
  );
};

export default IndexReportTable;
