import React, { useContext, useState } from "react";
import { motion } from "framer-motion";
import { ArrowRightIcon } from "lucide-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { userContext } from "../context/userContext";
import { handleCreateUser } from "../api";
import { toast } from "react-toastify";
import "./Register.css";
import { Helmet } from "react-helmet-async";
import { CanonicalURL } from "../BASE_URL";
import logo from "../images/logo.png";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  mobileNumber: Yup.string().required("Mobile number is required"),
  password: Yup.string()
    .matches(
      /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/,
      "Password must be at least 8 characters long and contain at least one number, one letter, and one special character."
    )
    .required("Password is required"),
  websiteUrl: Yup.string()
    .required("Website URL is required")
    .matches(
      /^(?!www\.)[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\.[a-zA-Z]{2,})?$/,
      'Please enter a valid domain, like "domainName.com"'
    ),
});

const Register = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(false);
  const { data, setData } = useContext(userContext);
  const [phone, setPhone] = useState();

  const naviatate = useNavigate();

  const [userDetails, setUserDetails] = useState({
    name: "",
    email: data?.email,
    image: "",
    password: "",
    mobile: "",
    websiteUrl: "",
  });

  return (
    <div className="flex items-center justify-center min-h-screen flex-col gap-5 ">
      <Helmet>
        <title>Indexer SignUp</title>

        <link rel="canonical" href={`${CanonicalURL}/sign-up`} />
      </Helmet>
      <div className="w-[280px] object-contain mr-6">
        <img src={logo} alt="logo" width="100%" height="100%" />
      </div>
      <div className="2xl:w-[450px] xl:w-[450px] lg:w-[450px] md:w-[450px] w-full border border-gray-200 p-6 rounded-[20px] ">
        <div className="">
          <div className="mt-3">
            <p className="text-2xl font-semibold antialiased">
              Create your account
            </p>
            <p className="text-slate-400 antialiased ">
              to continue to Swift index
            </p>
          </div>

          <Formik
            initialValues={{
              name: userDetails?.name,
              email: userDetails?.email,
              mobileNumber: userDetails?.mobile,
              password: userDetails?.password,
              websiteUrl: userDetails?.websiteUrl,
            }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              console.log(values);
              setData({ ...data, email: values.email });
              try {
                setLoading(true);
                const res = await handleCreateUser(
                  values.name,
                  values.email,
                  values.image,
                  values.password,
                  values.mobileNumber,
                  values.websiteUrl
                );

                if (res.status === 200) {
                  toast.success(res?.data?.message, {
                    position: "top-right",
                  });
                  naviatate("/verify-otp");
                } else if (res.status === 201) {
                  toast.success(res?.data?.message, {
                    position: "top-right",
                  });
                  naviatate("/verify-otp");
                } else {
                  toast.error(res?.data?.error, {
                    position: "top-right",
                  });
                }
              } catch (error) {
                console.error(error);
              } finally {
                setLoading(false);
              }
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur }) => (
              <Form>
                <div className="mt-6">
                  <div>
                    <Field
                      type="text"
                      name="name"
                      className={`outline-none border ${
                        errors.name && touched.name
                          ? "border-red-500 focus:ring-2 focus:ring-red-200 focus:transition-all focus:duration-300"
                          : "border-gray-200 focus:ring-2 focus:ring-indigo-200 focus:transition-all focus:duration-300"
                      } w-full h-12 rounded-[8px] focus:ring-2 focus:ring-indigo-200 focus:transition-all focus:duration-300 indent-3 mt-2`}
                      placeholder="Enter your name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.name && errors.name && (
                      <div className="text-red-500">{errors.name}</div>
                    )}
                  </div>
                  <Field
                    type="email"
                    name="email"
                    className={`outline-none border ${
                      errors.email && touched.email
                        ? "border-red-500 focus:ring-2 focus:ring-red-200 focus:transition-all focus:duration-300"
                        : "border-gray-200 focus:ring-2 focus:ring-indigo-200 focus:transition-all focus:duration-300"
                    } w-full h-12 rounded-[8px] focus:ring-2 focus:ring-indigo-200 focus:transition-all focus:duration-300 indent-3 mt-5`}
                    placeholder="Enter your email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.email && errors.email && (
                    <div className="text-red-500">{errors.email}</div>
                  )}
                  <Field
                    type="password"
                    name="password"
                    className={`outline-none border ${
                      errors.password && touched.password
                        ? "border-red-500 focus:ring-2 focus:ring-red-200 focus:transition-all focus:duration-300"
                        : "border-gray-200 focus:ring-2 focus:ring-indigo-200 focus:transition-all focus:duration-300"
                    } w-full h-12 rounded-[8px]  indent-3 mt-5`}
                    placeholder="Enter your password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {values.password && values.password.length >= 8 && (
                    <div className="text-green-500">
                      ✓ Contains at least 8 characters
                    </div>
                  )}
                  {values.password && /\d/.test(values.password) && (
                    <div className="text-green-500">
                      ✓ Contains at least one number
                    </div>
                  )}
                  {values.password && /[a-zA-Z]/.test(values.password) && (
                    <div className="text-green-500">
                      ✓ Contains at least one letter
                    </div>
                  )}
                  {values.password && /[^a-zA-Z0-9]/.test(values.password) && (
                    <div className="text-green-500">
                      ✓ Contains at least one special character
                    </div>
                  )}
                  {touched.password && errors.password && (
                    <div className="text-red-500">{errors.password}</div>
                  )}
                  <div className="mt-5  ">
                    <PhoneInput
                      country={"in"}
                      placeholder="Enter your mobile number"
                      enableSearch={true}
                      value={values.mobileNumber}
                      onChange={(value) => {
                        handleChange({
                          target: { name: "mobileNumber", value: value },
                        });
                      }}
                      inputProps={{
                        name: "mobileNumber",
                        required: true,
                      }}
                    />
                    {touched.mobileNumber && errors.mobileNumber && (
                      <div className="text-red-500">{errors.mobileNumber}</div>
                    )}
                  </div>

                  <div className="flex mt-5 items-center gap-1">
                    <p className="antialiased tracking-normal text-slate-400">
                      https://www.
                    </p>
                    <Field
                      name="websiteUrl"
                      className={`outline-none border ${
                        errors.websiteUrl && touched.websiteUrl
                          ? "border-red-500 focus:ring-2 focus:ring-red-200 focus:transition-all focus:duration-300"
                          : "border-gray-200 focus:ring-2 focus:ring-indigo-200 focus:transition-all focus:duration-300"
                      } w-full h-12 rounded-[8px] p-3 `}
                      placeholder="Ex : yourdomain.com"
                      value={values.websiteUrl} // Join the array values with commas
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>

                  {touched.websiteUrl && errors.websiteUrl && (
                    <div className="text-red-500">{errors.websiteUrl}</div>
                  )}
                  <button
                    disabled={loading}
                    className={`mt-6 bg-indigo-400  rounded-[8px] h-12 text-white flex justify-center items-center gap-1 w-full ${
                      loading
                        ? ""
                        : "hover:ring-2 hover:bg-indigo-400 hover:ring-indigo-200 hover:transition-all hover:duration-300"
                    }`}
                  >
                    {loading ? (
                      <div aria-label="Loading..." role="status">
                        <svg
                          class="animate-spin w-6 h-6 fill-white"
                          viewBox="3 3 18 18"
                        >
                          <path
                            class="opacity-20"
                            d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                          ></path>
                          <path d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
                        </svg>
                      </div>
                    ) : (
                      <p>Sign Up</p>
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <div className="mt-6">
            <p className="text-slate-400 antialiased text-center">
              Already have an account ?
            </p>
            <div>
              <Link to="/">
                <p className="text-blue-400  hover:text-orange-400 hover:transition-all hover:duration-300 text-center mt-2">
                  Sign in
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <p>
          © 2023{" "}
          <a
            href="https://www.csquaretech.com/"
            target="_blank"
            className="hover:text-blue-400 antialiased tracking-normal transition-all duration-300 hover:underline text-indigo-400 hover:duration-300 hover:transition-all"
          >
            CSTech
          </a>
          . All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Register;
