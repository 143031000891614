export const generateOTP = () => {
  const chars = "0123456789";

  let OTP = "";

  for (let i = 0; i < 6; i++) {
    OTP += chars[Math.floor(Math.random() * chars.length)];
  }

  return OTP;
};

export function convertToIST(utcDateString) {
  const utcDate = new Date(utcDateString);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
    timeZone: "Asia/Kolkata",
  };

  const istDateString = utcDate.toLocaleString("en-US", options);

  return istDateString;
}
export function convertToIST1(utcDateString) {
  const utcDate = new Date(utcDateString);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",

    timeZone: "Asia/Kolkata",
  };

  const istDateString = utcDate.toLocaleString("en-US", options);

  return istDateString;
}

export function maskEmail(email) {
  if (typeof email !== "string" || email.length < 5) {
    return "";
  }

  const atIndex = email.indexOf("@");
  if (atIndex === -1) {
    return "";
  }

  const beforeAt = email.slice(0, atIndex);
  const firstTwo = beforeAt.slice(0, 2);
  const lastThree = beforeAt.slice(-4);

  const maskedEmail = `${firstTwo}****${lastThree}${email.slice(atIndex)}`;

  return maskedEmail;
}

export function extractDomain(inputString) {
  // Define prefixes to be removed
  const prefixesToRemove = ["https://", "http://", "www."];

  // Remove prefixes from the input string
  let cleanedString = inputString;
  prefixesToRemove.forEach((prefix) => {
    cleanedString = cleanedString.replace(prefix, "");
  });

  // Define a prefix that indicates the start of the domain in the cleaned string
  const prefix = "sc-domain:";

  // Find the index of the prefix in the cleaned string
  const startIndex = cleanedString.indexOf(prefix);

  // Check if the prefix is found in the cleaned string
  if (startIndex !== -1) {
    // Extract the substring that follows the prefix
    let domainSubstring = cleanedString.substring(startIndex + prefix.length);

    // Trim any leading or trailing whitespace from the extracted substring
    domainSubstring = domainSubstring.replace(/\/+$/, "");

    // Remove a trailing slash at the end of the domain (if present)

    // Return the cleaned domain
    return domainSubstring;
  } else {
    // If the prefix is not found, return the cleaned string
    return cleanedString;
  }
}
