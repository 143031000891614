import React from "react";
import logo from "../images/logo.png";

import { FaFacebook, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="bg-gray-50 mt-10 border border-gray-100">
      {/* <div className="grid grid-cols-3 px-20 py-10 gap-20">
        <div className="mr-5">
          <div className="w-10 h-10">
            <img src={logo} width="100%" height="100%" />
          </div>
          <div>
            <p className="mt-5 text-slate-600 antialiased tracking-normal leading-normal">
              The tagline goes hereThe tagline goes hereThe tagline goes hereThe
              tagline goes here{" "}
            </p>
          </div>

          <div className="mt-8 flex gap-5 items-center">
            <FaFacebook
              size={24}
              className="text-slate-400 hover:text-indigo-400 hover:transition-all hover:duration-300 transition-all duration-300 cursor-pointer"
            />
            <FaTwitter
              size={24}
              className="text-slate-400 hover:text-indigo-400 hover:transition-all hover:duration-300 transition-all duration-300 cursor-pointer"
            />
            <FaYoutube
              size={24}
              className="text-slate-400 hover:text-indigo-400 hover:transition-all hover:duration-300 transition-all duration-300 cursor-pointer"
            />
            <FaLinkedin
              size={24}
              className="text-slate-400 hover:text-indigo-400 hover:transition-all hover:duration-300 transition-all duration-300 cursor-pointer"
            />
          </div>
        </div>
        <div>Hello1</div>
        <div>Hello2</div>
      </div> */}

      <div className="px-3">
        <div className="p-5 flex flex-col justify-center items-center">
          <p>
            © 2023{" "}
            <a
              href="https://www.csquaretech.com/"
              target="_blank"
              className="hover:text-blue-400 antialiased tracking-normal transition-all duration-300 hover:underline text-indigo-400 hover:duration-300 hover:transition-all"
            >
              CSTech
            </a>
            . All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
