import React, { useState } from "react";
import { motion } from "framer-motion";
import { RefreshCcw } from "lucide-react";
import {
  allWebsites,
  getAllserviceAccounts,
  handleGetUserById,
  handleSyncWebsites,
} from "../api";

import { useSelector } from "react-redux";
import useSWR, { mutate } from "swr";
import { toast } from "react-toastify";
import AllWebsites from "./AllWebsites";
import { Helmet } from "react-helmet-async";
import { CanonicalURL } from "../BASE_URL";

const Websites = () => {
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const fetcher = async () => {
    const response = await handleGetUserById(user?._id);

    return response?.data;
  };
  const fetcher1 = async () => {
    const response = await getAllserviceAccounts(user?._id);

    return response?.data;
  };
  const fetcher2 = async () => {
    const response = await allWebsites(user?._id);

    return response?.data;
  };

  const res1 = useSWR("user", fetcher);
  const res2 = useSWR("serviceaccounts", fetcher1);

  const res3 = useSWR("websites", fetcher2);

  const syncWebistes = async () => {
    setLoading(true);
    try {
      if (res2?.data && res1?.data) {
        const res = await handleSyncWebsites(
          res2?.data?.[0],
          res1?.data?.websiteUrl
        );

        if (res.status === 200) {
          mutate("websites");
          toast.success(res?.data?.message, {
            position: "top-right",
          });
        } else {
          toast.error(res?.data?.error, {
            position: "top-right",
          });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Swift Indexer Websites</title>
        <meta
          name="description"
          content="Unlock the power of Indexer - your ultimate solution for automatic website indexing.Boost online visibility with our cutting-edge tools for rapid,efficient page indexing."
        />

        <link rel="canonical" href={`${CanonicalURL}/websites`} />
      </Helmet>
      <div className="flex items-center justify-between flex-wrap">
        <h1 className=" antialiased tracking-wide leading-normal text-[24px] font-semibold">
          Your Websites
        </h1>
        <motion.button
          onClick={syncWebistes}
          disabled={loading}
          className={`bg-indigo-400 text-white rounded-[8px] h-10  2xl:w-[220px] xl:w-[220px] lg:w-[220px] md:w-[220px] w-[160px] flex items-center justify-center gap-2 ${
            loading
              ? "opacity-70 cursor-not-allowed"
              : "hover:ring-2 hover:ring-indigo-300 hover:transition-all hover:duration-300"
          }`}
        >
          <motion.div>
            <RefreshCcw
              className={`w-4 h-4 mt-[2px] ${loading && "animate-spin"}`}
            />
          </motion.div>
          <p className="">{loading ? "Syncing..." : "Sync websites"}</p>
        </motion.button>
      </div>

      {res3?.isLoading && !res3?.data && (
        <div className="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2">
          <div aria-label="Loading..." role="status">
            <svg
              className="animate-spin w-6 h-6 fill-slate-800"
              viewBox="3 3 18 18"
            >
              <path
                className="opacity-20"
                d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
              ></path>
              <path d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
            </svg>
          </div>
        </div>
      )}

      <div className="mt-10">
        {res3?.data?.length > 0 ? (
          <AllWebsites websites={res3?.data} />
        ) : (
          <div
            className={`absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ${
              res3?.isLoading && "hidden"
            }`}
          >
            No websites found, Please clik on sync websites to sync your
            website's.
          </div>
        )}
      </div>
    </div>
  );
};

export default Websites;
