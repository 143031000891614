import React, { useEffect, useState } from "react";
import { allUploads, allWebsites } from "../api";
import { useSelector } from "react-redux";
import WebsiteSelector from "./WebsiteSelector";
import useSWR from "swr";
import IndexReportTable from "./IndexReportTable";
import Banner from "./Banner";
import Combobox from "./Combobox";
import { Helmet } from "react-helmet-async";
import { CanonicalURL } from "../BASE_URL";

const IndexReport = () => {
  const { user } = useSelector((state) => state.auth);
  const [websites, setWebsites] = useState([]);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState();
  const [selectedId, setSelectedId] = useState();
  const [loading, setLoading] = useState(false);
  const [selcetedType, setSelectedType] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [filteredData, setFilteredData] = useState([]);

  const fetcher = async () => {
    const response = await allWebsites(user?._id);

    setWebsites(response?.data);
  };

  const fetcher1 = async () => {
    try {
      setLoading(true);
      if (selectedId) {
        const response = await allUploads(selectedId);

        setData(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    Promise.all([fetcher(), fetcher1()]);
  }, [user, selectedId]);

  return (
    <>
      <div>
        <Helmet>
          <title>Swift Indexer IndexReport</title>
          <meta
            name="description"
            content="Unlock the power of Indexer - your ultimate solution for automatic website indexing.Boost online visibility with our cutting-edge tools for rapid,efficient page indexing."
          />
          <link rel="canonical" href={`${CanonicalURL}/index-report`} />
        </Helmet>
        <div className="">
          <div className="flex justify-between items-center">
            <h1 className="font-semibold antialiased tracking-wide text-[24px] w-full">
              Index Report
            </h1>
            <WebsiteSelector
              websites={websites}
              setSelected={setSelected}
              selected={selected}
              setSelectedId={setSelectedId}
            />
          </div>

          <div className="mt-6">
            <div>
              <p className="text-slate-400 antialiased tracking-normal">
                search
              </p>
              <div className="flex items-center gap-3 flex-wrap">
                <Combobox
                  setSelected={setSelectedType}
                  selected={selcetedType}
                />

                <input
                  className="outline-none border border-gray-200 focus:ring-2 focus:ring-indigo-200 focus:transition-all focus:duration-300 h-9 w-[300px] rounded-md mt-1.5 indent-3"
                  placeholder="search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <IndexReportTable
              data={data?.data}
              isLoading={loading}
              searchQuery={searchQuery}
              selectedType={selcetedType.name}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IndexReport;
