import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function NotFoundPage() {
  const { token } = useSelector((state) => state.auth);
  return (
    <>
      <main className="grid min-h-screen place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-indigo-600">
            404
          </h1>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Oops!, Page not found
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Sorry, we couldn’t find the page you’re looking for.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            {token ? (
              <Link
                to="/dashboard"
                className="rounded-md bg-indigo-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Go back home
              </Link>
            ) : (
              <Link
                to="/"
                className="rounded-md bg-indigo-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Go back home
              </Link>
            )}
            <Link
              to="/contact-support"
              className="text-sm font-semibold text-gray-900"
            >
              Contact support <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        </div>

        <div className="mt-2">
          <p>
            © 2023{" "}
            <a
              href="https://www.csquaretech.com/"
              target="_blank"
              className="hover:text-blue-400 antialiased tracking-normal transition-all duration-300 hover:underline text-indigo-400 hover:duration-300 hover:transition-all"
            >
              Csquaretech
            </a>
            . All Rights Reserved.
          </p>
        </div>
      </main>
    </>
  );
}
