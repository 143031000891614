import React, { useState } from "react";
import FileUpload1 from "./FileUpload1";
import { UploadUrls, getWebsiteById } from "../api";
import { useNavigate, useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import { extractDomain } from "../utils/functions";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { DownloadCloudIcon } from "lucide-react";
import { Helmet } from "react-helmet-async";
import { CanonicalURL } from "../BASE_URL";

const RemoveUrls = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const [csvData, setCSVData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const fetcher = async () => {
    const response = await getWebsiteById(id);

    return response?.data;
  };

  const { data, isLoading } = useSWR("website", fetcher);

  const filteredCSVData = csvData.filter((item) =>
    item?.["url"]?.includes(extractDomain(data?.siteurl))
  );

  let CSVDataUrls = [];

  if (csvData) {
    CSVDataUrls.push(filteredCSVData?.map((item) => item?.["url"]));
  }

  const submitUrlsToDB = async () => {
    setLoading(true);
    try {
      if (CSVDataUrls[0].length > 0 && data) {
        const res = await UploadUrls(
          CSVDataUrls[0],
          user?._id,
          "remove",
          data?._id,
          data?.siteurl
        );

        if (res.status === 200) {
          toast.success(res?.data?.message, {
            position: "top-right",
          });
          mutate("pages");
          navigate(`/websites/view-pages/${data?._id}`);
        } else {
          toast.success(res?.data?.error, {
            position: "top-right",
          });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const downloadCsv = () => {
    const csvUrl = "/samples/RemoveUrls.csv";

    const link = document.createElement("a");
    link.href = csvUrl;
    link.download = "RemoveUrls.csv"; // Specify the desired download filename
    document.body.appendChild(link);

    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  return (
    <div>
      <Helmet>
        <title>Swift Indexer RemoveUrl's</title>
        <meta
          name="description"
          content="Unlock the power of Indexer - your ultimate solution for automatic website indexing.Boost online visibility with our cutting-edge tools for rapid,efficient page indexing."
        />
        <link rel="canonical" href={`${CanonicalURL}/removeurls/${id}`} />
      </Helmet>
      <div className="flex items-center justify-between">
        <h1 className="font-semibold antialiased tracking-wide leading-normal text-[24px]">
          Upload URLs to remove Indexing
        </h1>
        <div>
          <button
            onClick={downloadCsv}
            className="bg-indigo-400 rounded-[8px] h-10 w-[240px] text-white flex items-center gap-3 justify-center"
          >
            <DownloadCloudIcon />
            <p>Download sample sheet</p>
          </button>
        </div>
      </div>

      <div className="bg-white w-full border border-gray-200 rounded-[20px] col-span-3 p-8 mt-10">
        <p className="font-semibold text-[22px] antialiased tracking-normal">
          Upload .csv file
        </p>
        <p className="text-slate-400 antialiased tracking-normal">
          Please provide the .csv file which contains all your URLs which you to
          remove indexing
        </p>

        <div className="mt-6">
          <FileUpload1
            name="Drag and drop .csv file or click here"
            supports=".csv"
            validTypes="text/csv"
            setSelectedFile={setSelectedFile}
            setCSVData={setCSVData}
            value="add"
          />

          <div className="mt-4">
            <button
              disabled={!selectedFile || loading}
              onClick={submitUrlsToDB}
              className={`bg-slate-700 text-white w-[200px] h-10 rounded-[8px] flex justify-center items-center  ${
                (!selectedFile || loading) && "opacity-60 "
              }`}
            >
              {loading ? (
                <div aria-label="Loading..." role="status">
                  <svg
                    class="animate-spin w-6 h-6 fill-white"
                    viewBox="3 3 18 18"
                  >
                    <path
                      class="opacity-20"
                      d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                    ></path>
                    <path d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
                  </svg>
                </div>
              ) : (
                <p>Save changes</p>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveUrls;
