import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { handleChangePassword } from "../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const { user } = useSelector((state) => state.auth);
  const [Loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Old password is required"),
      newPassword: Yup.string()
        .matches(
          /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/,
          "Password must be at least 8 characters long and contain at least one number, one letter, and one special character."
        )
        .required("New Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm new password is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      try {
        const res = await handleChangePassword(
          user?._id,
          values.password,
          values.newPassword
        );

        if (res.status === 200) {
          toast.success(res?.data?.message, {
            position: "top-right",
          });
          resetForm({
            values: {
              password: "",
              newPassword: "",
              confirmPassword: "",
            },
          });
        } else {
          toast.error(res?.data?.error, {
            position: "top-right",
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div>
      <h1 className="font-serif antialiased tracking-wide leading-normal text-[20px]">
        Change Password
      </h1>

      <form onSubmit={formik.handleSubmit}>
        <div className="mt-6">
          <div className="flex flex-col">
            <label
              htmlFor="password"
              className="text-slate-400 antialiased tracking-normal"
            >
              Old Password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              className={`outline-none border ${
                formik.errors.password && formik.touched.password
                  ? "border-red-500 focus:ring-2 focus:ring-red-200 focus:transition-all focus:duration-300"
                  : "border-gray-200 focus:ring-2 focus:ring-indigo-200 focus:transition-all focus:duration-300"
              }  h-12 rounded-[8px] focus:ring-2 focus:ring-indigo-200 focus:transition-all focus:duration-300 indent-3 mt-2 w-[400px]`}
              placeholder="Enter your Old password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="text-red-500">{formik.errors.password}</div>
            )}
          </div>

          <div className="mt-6 flex flex-col">
            <label
              htmlFor="newPassword"
              className="text-slate-400 antialiased tracking-normal"
            >
              New Password
            </label>
            <input
              id="newPassword"
              name="newPassword"
              type="password"
              className={`outline-none border ${
                formik.errors.newPassword && formik.touched.newPassword
                  ? "border-red-500 focus:ring-2 focus:ring-red-200 focus:transition-all focus:duration-300"
                  : "border-gray-200 focus:ring-2 focus:ring-indigo-200 focus:transition-all focus:duration-300"
              }  h-12 rounded-[8px] focus:ring-2 focus:ring-indigo-200 focus:transition-all focus:duration-300 indent-3 mt-2 w-[400px]`}
              placeholder="Enter your New password"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          {formik.values.newPassword &&
            formik.values.newPassword.length >= 8 && (
              <div className="text-green-500">
                ✓ Contains at least 8 characters
              </div>
            )}
          {formik.values.newPassword &&
            /\d/.test(formik.values.newPassword) && (
              <div className="text-green-500">
                ✓ Contains at least one number
              </div>
            )}
          {formik.values.newPassword &&
            /[a-zA-Z]/.test(formik.values.newPassword) && (
              <div className="text-green-500">
                ✓ Contains at least one letter
              </div>
            )}
          {formik.values.newPassword &&
            /[^a-zA-Z0-9]/.test(formik.values.newPassword) && (
              <div className="text-green-500">
                ✓ Contains at least one special character
              </div>
            )}

          <div className="mt-6 flex flex-col">
            <label
              htmlFor="confirmPassword"
              className="text-slate-400 antialiased tracking-normal"
            >
              Confirm New Password
            </label>
            <input
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              className={`outline-none border ${
                formik.errors.confirmPassword && formik.touched.confirmPassword
                  ? "border-red-500 focus:ring-2 focus:ring-red-200 focus:transition-all focus:duration-300"
                  : "border-gray-200 focus:ring-2 focus:ring-indigo-200 focus:transition-all focus:duration-300"
              }  h-12 rounded-[8px] focus:ring-2 focus:ring-indigo-200 focus:transition-all focus:duration-300 indent-3 mt-2 w-[400px]`}
              placeholder="Enter your Confirm password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.confirmPassword &&
              formik.errors.confirmPassword && (
                <div className="text-red-500">
                  {formik.errors.confirmPassword}
                </div>
              )}
          </div>

          <div className="mt-6">
            <button
              disabled={Loading}
              className={`mt-6 bg-slate-700  rounded-[8px] h-10 text-white flex justify-center items-center gap-1 w-[200px]`}
            >
              {Loading ? (
                <div aria-label="Loading..." role="status">
                  <svg
                    class="animate-spin w-6 h-6 fill-white"
                    viewBox="3 3 18 18"
                  >
                    <path
                      class="opacity-20"
                      d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                    ></path>
                    <path d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
                  </svg>
                </div>
              ) : (
                <p>Save changes</p>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
