import { ArrowRightIcon } from "lucide-react";

import React, { useContext, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { maskEmail } from "../utils/functions";
import { userContext } from "../context/userContext";
import { handleResendOTp, handleVerifyOTP, userLogin } from "../api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { login } from "../redux/actions/authActions";
import { Helmet } from "react-helmet-async";
import { CanonicalURL } from "../BASE_URL";
import logo from "../images/logo.png";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

const VerifyOTP = () => {
  const { data, setData } = useContext(userContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [Loading, setLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [OTP, setOTP] = useState(["", "", "", "", "", ""]);
  const otpInputs = useRef([]);
  const [timer, setTimer] = useState(60);
  const [timerOn, setTimerOn] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [wrongOTP, setWrongOTP] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("NA");
  const [loading1, setLoading1] = useState(false);

  const handleInput = (index, e) => {
    const value = e.target.value;
    if (value.length <= 1) {
      setOTP((prevOTP) => {
        const newOTP = [...prevOTP];
        newOTP[index] = value;
        return newOTP;
      });

      if (index < otpInputs.current.length - 1 && value !== "") {
        otpInputs.current[index + 1].focus();
      }
    }
    setWrongOTP(false);
  };

  useEffect(() => {
    setEmail(data?.email);
  }, [data]);

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && OTP[index] === "") {
      otpInputs.current[index - 1].focus();
      setWrongOTP(false);
      // Move focus to the previous input
    }
  };

  const VerifyOTP = async () => {
    setLoading(true);
    try {
      const res = await handleVerifyOTP(data.email, OTP.join(""));

      if (res.status === 200) {
        const user = await userLogin(data?.email, res?.data?.data?.password);

        if (user.status === 200) {
          dispatch(login(user.data.user?.token, user.data?.user));
          navigate("/service/new");
          toast.success(res?.data?.message, {
            position: "top-right",
          });
        }

        Cookies.remove("data");
        // navigate("/");
      } else if (res.status === 202) {
        setWrongOTP(true);
        setError(res?.data?.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let interval = null;
    if (timerOn) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    if (timer === 0) {
      setTimerOn(false);
      setShowResend(true);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timerOn, timer]);

  const startTimer = () => {
    setTimer(60);
    setTimerOn(true);
    setShowResend(false);
  };

  useEffect(() => {
    startTimer();
  }, []);

  const handleResend = async () => {
    setLoading1(true);
    try {
      const res = await handleResendOTp(email);

      if (res.status === 200) {
        toast.success(res.data?.message, {
          position: "top-right",
        });
        setTimer(60);
        setTimerOn(true);
        setShowResend(false);
      } else {
        toast.error(res.data?.error, {
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  return (
    <div className=" flex justify-center items-center h-screen">
      <Helmet>
        <title>Indexer Verify</title>

        <link rel="canonical" href={`${CanonicalURL}/verify-otp`} />
      </Helmet>
      <div className="2xl:w-[450px] xl:w-[450px] lg:w-[450px] md:w-[450px] w-full flex justify-center items-center flex-col">
        <div className="flex justify-center items-center ">
          <div className="w-[300px] object-contain ">
            <img src={logo} alt="logo" width="100%" height="100%" />
          </div>
        </div>
        <div className="w-full px-3 mt-5">
          <p className="font-foregr text-[28px] pt-5 font-serif antialiased tracking-wide leading-normal text-center ">
            Please Verify your OTP
          </p>
          <p className="text-slate-400 pt-2 antialiased text-center">
            We have sent your OTP to{" "}
            <span className="font-semibold text-slate-500 antialiased">
              {maskEmail(email)}
            </span>
          </p>
          <div className="mt-6 flex justify-center items-center  flex-col w-full ">
            <div id="otp" className=" ">
              {Array.from({ length: 6 }).map((_, index) => (
                <input
                  className={`m-1.5 border h-10 w-10 md:h-12 md:w-12 text-center form-control rounded ${
                    wrongOTP ? "border-red-500" : ""
                  }`}
                  type="text"
                  key={index}
                  ref={(el) => (otpInputs.current[index] = el)}
                  value={OTP[index]}
                  maxLength="1"
                  onChange={(e) => handleInput(index, e)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                />
              ))}
            </div>
            {error && (
              <div className="text-red-500 text-left mt-1 antialiased text-sm ml-3">
                {error}
              </div>
            )}
          </div>

          <div className="mt-3  flex justify-center items-center">
            <div>
              <p className="text-slate-400 antialiased tracking-normal text-center">
                Please check the spam folder in case if email is not there in
                your inbox.
              </p>
            </div>
          </div>
        </div>
        <motion.button
          className="mt-8 bg-indigo-400 w-full rounded-[8px] h-12 text-white flex justify-center items-center gap-1 2xl:w-[400px]"
          onHoverStart={() => setIsHovered(true)}
          onHoverEnd={() => setIsHovered(false)}
          onClick={VerifyOTP}
        >
          {Loading ? (
            <div aria-label="Loading..." role="status">
              <svg
                className="animate-spin w-6 h-6 fill-white"
                viewBox="3 3 18 18"
              >
                <path
                  className="opacity-20"
                  d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                ></path>
                <path d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
              </svg>
            </div>
          ) : (
            <p className="antialiased leading-normal tracking-wide">Continue</p>
          )}

          {!Loading && (
            <motion.div
              initial={{ x: 0 }}
              animate={{ x: isHovered ? 5 : 0 }}
              transition={{ duration: 0.2 }}
            >
              <ArrowRightIcon className="w-4 h-4 mt-[2px]" />
            </motion.div>
          )}
        </motion.button>

        <div className="mt-5">
          {showResend ? (
            <div className="mt-5 flex justify-center items-center">
              <button
                onClick={handleResend}
                disabled={loading1}
                className="text-[#0099cc] hover:text-orange-300 hover:transition-all hover:duration:300"
              >
                <div>
                  {loading1 ? (
                    <div>
                      <p>sending...</p>
                    </div>
                  ) : (
                    <p>Resend OTP</p>
                  )}
                </div>
              </button>
            </div>
          ) : (
            <div className="mt-4">
              <p className="text-slate-400 antialiased text-center">
                Resend OTP in: {Math.floor(timer / 60)}:
                {timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyOTP;
