import React, {
  Suspense,
  lazy,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useSelector } from "react-redux";
import Login from "./components/SignIn";
import VerifyOTP1 from "./components/VerifyOtp1";
import VerifyOTP from "./components/VerifyOtp";
import AddUrls from "./components/AddUrls";
import UpdateUrls from "./components/UpdateUrls";
import RemoveUrls from "./components/RemoveUrls";
import ServiceAccount from "./components/ServiceAccount";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Register from "./components/Register";
import Pricing from "./components/Pricing";
import Banner from "./components/Banner";
import useSWR from "swr";
import { getAllserviceAccounts } from "./api";
import NotFoundPage from "./components/NotFound";
import ContactSupport from "./components/ContactSupport";
import Footer from "./components/Footer";
import SeoReport from "./components/SeoReport";
import SEOReportDetails from "./components/seoReportDetails";
import Dashboard from "./components/Dashboard";
import Websites from "./components/Websites";
import ViewPages from "./components/ViewPages";
import ConsoleReport from "./components/ConsoleReport";
import IndexReport from "./components/IndexReport";
import Profile from "./components/Profile";

function App() {
  const navigate = useNavigate();
  const { token, user } = useSelector((state) => state.auth);
  const [serviceAccounts, setServiceAccounts] = useState([]);

  const fetcher1 = async () => {
    if (user) {
      const response = await getAllserviceAccounts(user?._id);
      setServiceAccounts(response?.data);
    }
  };

  useEffect(() => {
    if (!token && window.location.path === "/") {
      navigate("/");
    } else if (token && window.location.pathname === "/") {
      navigate("/dashboard");
    }
  }, [token]);

  useEffect(() => {
    fetcher1();
  }, [token, window.location.pathname]);

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  return (
    <div>
      <div className="">
        <Routes>
          <Route path="/contact-support" element={<ContactSupport />} />
        </Routes>
        {!token ? (
          <div className="">
            {window.location.pathname === "/pricing" ? (
              <div>
                <div className="flex flex-col gap-5">{/* <Navbar /> */}</div>
                <div className="2xl:px-28 2xl:py-5 xl:px-28 xl:py-5 lg:px-20 lg:py-5 md:px-10 md:py-5 px-3 py-3">
                  <Routes>
                    {/* <Route
                        path="/pricing"
                        element={<Pricing toast={toast} />}
                      /> */}
                    <Route path="*" element={<NotFoundPage />} />
                  </Routes>
                </div>
              </div>
            ) : (
              <div className="2xl:px-28 2xl:py-5 xl:px-28 xl:py-5 lg:px-20 lg:py-5 md:px-10 md:py-5 px-3 py-3">
                <Wrapper>
                  <Routes>
                    <Route
                      path="/sign-up"
                      element={<Register toast={toast} />}
                    />
                    <Route path="/" element={<Login toast={toast} />} />
                    <Route
                      path="/verify-otp"
                      element={<VerifyOTP toast={toast} />}
                    />
                    <Route
                      path="/verify"
                      element={<VerifyOTP1 toast={toast} />}
                    />
                    <Route path="*" element={<NotFoundPage />} />
                  </Routes>
                </Wrapper>
              </div>
            )}
          </div>
        ) : (
          <div>
            {window.location.pathname === "/service/new" ? (
              <div className="2xl:px-28 2xl:py-5 xl:px-28 xl:py-5 lg:px-20 lg:py-5 md:px-10 md:py-5 px-3 py-3 ">
                <Wrapper>
                  <Routes>
                    <Route
                      path="/service/new"
                      element={
                        <ServiceAccount serviceAccounts={serviceAccounts} />
                      }
                    />
                    <Route path="*" element={<NotFoundPage />} />
                  </Routes>
                </Wrapper>
              </div>
            ) : (
              <div>
                <div className="content-container">
                  <main>
                    <Wrapper>
                      <div>
                        <Navbar />
                        <div className="2xl:px-28 2xl:py-5 xl:px-28 xl:py-5 lg:px-20 lg:py-5 md:px-10 md:py-5 px-3 py-3">
                          <Routes>
                            <Route
                              path="/dashboard"
                              element={
                                <Dashboard serviceAccounts={serviceAccounts} />
                              }
                            />

                            <Route path="/websites" element={<Websites />} />
                            <Route path="*" element={<NotFoundPage />} />
                            <Route
                              path="/websites/add-urls/:id"
                              element={<AddUrls />}
                            />
                            <Route
                              path="/websites/update-urls/:id"
                              element={<UpdateUrls />}
                            />
                            <Route
                              path="/websites/remove-urls/:id"
                              element={<RemoveUrls />}
                            />
                            <Route
                              path="/websites/view-pages/:id"
                              element={<ViewPages />}
                            />

                            <Route
                              path="/console-report"
                              element={<ConsoleReport />}
                            />
                            {/* <Route path="/seo-report" element={<SeoReport />} /> */}
                            {/* <Route
                          path="/seo-report/:name"
                          element={<SEOReportDetails />}
                        /> */}
                            <Route
                              path="/index-report"
                              element={<IndexReport />}
                            />
                            <Route path="/profile" element={<Profile />} />
                          </Routes>
                        </div>
                      </div>
                    </Wrapper>
                  </main>
                </div>

                <footer className="footer--pin">
                  <Footer />
                </footer>
              </div>
            )}
          </div>
        )}
      </div>

      <ToastContainer />
    </div>
  );
}

export default App;
