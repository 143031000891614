import { Fragment, useEffect, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

export default function WebsiteSelector({
  websites,
  setSelected,
  selected,
  setSelectedId,
}) {
  useEffect(() => {
    setSelected(websites[0]?.siteurl);
    setSelectedId(websites[0]?._id);
  }, [websites, selected]);

  return (
    <div className="w-[300px] relative top-0 z-0">
      {/* <Combobox value={selected} onChange={setSelected}>
        <div className=" mt-1">
          <div className=" w-full cursor-default overflow-hidden rounded-lg bg-white text-left border border-gray-200  outline-none sm:text-sm ">
            <Combobox.Button className=" w-full border-none py-2 pl-3 pr-3 text-sm leading-5 text-gray-900 outline-none flex items-center justify-between">
              <span className="block truncate font-medium">{selected}</span>
              <ChevronUpDownIcon
                className="h-4 w-4 text-gray-400 "
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {websites.map((person) => (
                <Combobox.Option
                  key={person._id}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? "bg-teal-600 text-white" : "text-gray-900"
                    }`
                  }
                  value={person.siteurl}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {person.siteurl}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                            active ? "text-white" : "text-teal-600"
                          }`}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox> */}
    </div>
  );
}
