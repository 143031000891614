import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleAutoIndexing } from "../api";
import { toast } from "react-toastify";

const AllWebsites = ({ websites }) => {
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsChecked(websites?.[0]?.autoIndex);
  }, [websites]);

  const AutoIndexing = async (id) => {
    try {
      const updatedValue = !isChecked;

      const res = await handleAutoIndexing(updatedValue, id);

      if (res?.status === 200) {
        toast.success(res?.data?.message, {
          position: "top-right",
        });
        setIsChecked(updatedValue);
      } else {
        toast.error(res?.data?.error, {
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 ">
        {websites?.map((item, index) => (
          <div key={index}>
            <div
              className="bg-white border border-gray-200 rounded-tr-[8px] rounded-tl-[8px] p-6"
              key={index}
            >
              <div className="flex items-start justify-between">
                <div>
                  <p className="font-medium antialiased tracking-normal">
                    {item.siteurl}
                  </p>
                </div>
                <label className="relative inline-flex items-center me-5 cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    className="sr-only peer"
                    checked={isChecked}
                    onChange={() => AutoIndexing(item._id)}
                  />
                  <div class="w-11 h-6 bg-gray-300 rounded-full dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                </label>
              </div>
              <p className="text-slate-400 w-full mt-1 antialiased text-[16px]">
                Turn on to initiate auto indexing for you pages.
              </p>

              <div className="flex gap-8">
                <button
                  onClick={() => navigate(`/websites/view-pages/${item._id}`)}
                  className="border border-gray-200 w-[120px] h-10 mt-5 rounded-[8px] hover:bg-gray-100 hover:transition-all hover:duration-300"
                >
                  View
                </button>
              </div>
            </div>
            <div className="grid grid-cols-3">
              <button
                onClick={() => navigate(`/websites/add-urls/${item._id}`)}
                className="border border-gray-200 w-full h-10 rounded-bl-[8px]   hover:bg-gray-100 hover:transition-all hover:duration-300"
              >
                Add urls
              </button>
              <button
                onClick={() => navigate(`/websites/update-urls/${item._id}`)}
                className="border border-gray-200 w-full h-10   hover:bg-gray-100 hover:transition-all hover:duration-300"
              >
                Update Urls
              </button>
              <button
                onClick={() => navigate(`/websites/remove-urls/${item._id}`)}
                className="border border-gray-200 w-full h-10 rounded-br-[8px]  hover:bg-gray-100 hover:transition-all hover:duration-300"
              >
                Remove Urls
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllWebsites;
