import React from "react";
import { convertToIST } from "../utils/functions";

const DashboardUrlInspectionTable = ({ data }) => {
  let counter = 0;
  return (
    <div>
      {data?.some((item) =>
        item.indexUrls.some((url) => url.urlType !== "")
      ) ? (
        <>
          <div className=" overflow-x-auto mt-3 z-[100px]">
            <table className="w-full text-sm text-left rtl:text-right border border-gray-200 shadow-sm zIndex-[100px]">
              <thead className="text-xs  uppercase bg-gray-50  border-b">
                <tr>
                  <th scope="col" className="px-6 py-3 border-r">
                    S.no
                  </th>
                  <th scope="col" className="px-6 py-3 border-r">
                    URL
                  </th>
                  <th scope="col" className="px-6 py-3 border-r">
                    created at
                  </th>

                  <th scope="col" className="px-6 py-3 border-r">
                    status
                  </th>
                  <th scope="col" className="px-6 py-3 border-r">
                    last indexed
                  </th>
                </tr>
              </thead>
              <tbody>
                {data
                  ?.map?.((item, index) =>
                    item.indexUrls.map((url, i) => {
                      let totalUrlsRendered = 0;
                      if (url?.urlType !== "" && totalUrlsRendered < 10) {
                        counter++;
                        totalUrlsRendered++;
                        return (
                          <tr className="bg-white border-b" key={counter}>
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium  whitespace-nowrap border-r"
                            >
                              {counter}
                            </th>
                            <td className="px-6 py-4 border-r">{url?.url}</td>
                            <td className="px-6 py-4 border-r">
                              {url?.createdDate}
                            </td>

                            <td className="px-6 py-4 border-r">
                              {url?.indexCoverageState || "NA"}
                            </td>
                            <td className="px-6 py-4 border-r">
                              {url?.urlindexedTime === ""
                                ? "0"
                                : url?.urlindexedTime}
                            </td>
                          </tr>
                        );
                      }
                      return null;
                    })
                  )
                  .flat()
                  .filter(Boolean)
                  .slice(0, 10)}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div
          className={`absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2`}
        ></div>
      )}
    </div>
  );
};

export default DashboardUrlInspectionTable;
