import React, { useEffect, useState } from "react";
import useSWR, { mutate } from "swr";
// import UploadProfile from "./UploadProfile";
import { handleGetUserById, handleUpdateProfile } from "../api";
import { useSelector } from "react-redux";
import UploadProfile from "./UploadProfile";
import { toast } from "react-toastify";

const BasicDetails = ({ newdata, setData, setLoading }) => {
  const { user } = useSelector((state) => state.auth);
  const [Loading1, setLoading1] = useState(false);

  const [imageFile, setImageFile] = useState(null);

  const fetcher = async () => {
    setLoading(true);
    try {
      if (user) {
        const response = await handleGetUserById(user?._id);
        setData(response?.data);

        return response;
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const { data, isLoading } = useSWR("profile", fetcher);

  const updateProfile = async () => {
    setLoading1(true);
    try {
      const formdata = new FormData();
      formdata.append("profile", imageFile);

      const res = await handleUpdateProfile(formdata, user?._id);

      if (res.status === 200) {
        mutate("profile");
        toast.success(res?.data?.message, {
          position: "top-right",
        });
      } else {
        toast.error(res?.data?.error, {
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  return (
    <div>
      <h1 className="font-serif antialiased tracking-wide leading-normal text-[20px]">
        Basic Details
      </h1>

      {!isLoading ? (
        <div>
          <div className="mt-6 grid grid-cols-2 gap-10">
            <div>
              <div>
                <p className="text-slate-400 antialiased tracking-normal">
                  Name
                </p>
                <input
                  className="outline-none h-12 border border-gray-300 w-full rounded-[8px] indent-3 focus:ring-2 focus:ring-indigo-200 focus:transition-all focus:duration-300 mt-2"
                  placeholder="Enter your Name"
                  value={newdata?.name}
                  onChange={(e) =>
                    setData({ ...newdata, name: e.target.value })
                  }
                  type="text"
                />
              </div>
              <div className="mt-8">
                <p className="text-slate-400 antialiased tracking-normal">
                  Website URL
                </p>
                <input
                  className="outline-none h-12 border border-gray-300 w-full rounded-[8px] indent-3 focus:ring-2 focus:ring-indigo-200 focus:transition-all focus:duration-300 mt-2"
                  placeholder="Enter your website URL"
                  value={newdata?.websiteUrl}
                  disabled
                  type="text"
                />
              </div>
            </div>

            <div>
              <div>
                <p className="text-slate-400 antialiased tracking-normal">
                  Email Address
                </p>
                <input
                  className="outline-none h-12 border border-gray-300 w-full rounded-[8px] indent-3 focus:ring-2 focus:ring-indigo-200 focus:transition-all focus:duration-300 mt-2"
                  placeholder="Enter your email"
                  value={newdata?.email}
                  disabled
                  type="text"
                />
              </div>
              <div className="mt-8">
                <p className="text-slate-400 antialiased tracking-normal">
                  Mobile Number
                </p>
                <input
                  className="outline-none h-12 border border-gray-300 w-full rounded-[8px] indent-3 focus:ring-2 focus:ring-indigo-200 focus:transition-all focus:duration-300 mt-2"
                  placeholder="Enter your Mobile"
                  value={newdata?.mobile || ""}
                  onChange={(e) =>
                    setData({ ...newdata, mobile: e.target.value })
                  }
                  type="text"
                />
              </div>
            </div>
          </div>

          <div className="mt-6">
            <p className="text-slate-400 antialiased tracking-normal">
              Update your Profile Picture
            </p>
            <div className="mt-2">
              <UploadProfile setImageFile={setImageFile} />
            </div>
          </div>

          <div className="mt-4">
            <button
              onClick={updateProfile}
              disabled={!imageFile}
              className={`bg-slate-700 text-white w-[200px] h-10 rounded-[8px] flex justify-center items-center  ${
                (!imageFile || Loading1) && "opacity-60"
              }`}
            >
              {Loading1 ? (
                <div aria-label="Loading..." role="status">
                  <svg
                    class="animate-spin w-6 h-6 fill-white"
                    viewBox="3 3 18 18"
                  >
                    <path
                      class="opacity-20"
                      d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                    ></path>
                    <path d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
                  </svg>
                </div>
              ) : (
                <p>Save changes</p>
              )}
            </button>
          </div>
        </div>
      ) : (
        <div className="">
          <div aria-label="Loading..." role="status">
            <svg
              className="animate-spin w-6 h-6 fill-slate-800"
              viewBox="3 3 18 18"
            >
              <path
                className="opacity-20"
                d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
              ></path>
              <path d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};

export default BasicDetails;
